<!--
This Vue.js component is a basic layout with a .main container that fills 100% of the width and 30% of the viewport height (30vh). It centers its content both horizontally and vertically using Flexbox. The .txt class is also prepared for styling text, though it appears to be incomplete (commented-out max-width).

Key Points:
Container Styling: The .main container uses Flexbox to center content both horizontally and vertically.
Basic Structure: There’s a placeholder div with the class content, but no actual functionality or interactivity.
Text Styling: The .txt class suggests there might be a heading (h1) text, but it's not fully implemented here.
-->
<template>
  <div class="main">
    <div class="content"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt {
  // max-width: 250px;
  h1 {
    font-size: 4rem;
    margin: 0.2rem 0;
  }
}
</style>
