<!--
Summary of the Code:
This Vue.js component is a font-awesome icon renderer. It dynamically renders different types of Font Awesome icons based on the props passed to it (fa, fas, far, or fab). Depending on which prop is provided, it will display either a regular, solid, or brand icon from Font Awesome. The conditional rendering is done using Vue's v-if, v-else-if, and v-else directives.

Key Points:
Props: The component accepts four props (fa, fas, far, fab) corresponding to different types of Font Awesome icons.
Icon Rendering: It dynamically renders the correct icon based on which prop is passed, using Vue's conditional rendering.
Fallback: If none of the props are provided, it defaults to rendering an empty icon <i> element.
-->
<template>
  <i :class="`fa fa-${fa}`" aria-hidden="true" v-if="fa"></i>
  <i :class="`fas fa-${fas}`" aria-hidden="true" v-else-if="fas"></i>
  <i :class="`far fa-${far}`" aria-hidden="true" v-else-if="far"></i>
  <i :class="`fab fa-${fab}`" aria-hidden="true" v-else></i>
</template>

<script>
export default {
  props: {
    fa: String,
    fas: String,
    far: String,
    fab: String,
  },
  mounted() {},
};
</script>

<style scoped>
/* .fa, */
/* .fas { */
/* font-size: 1.5em; */
/* -webkit-text-stroke: 0.01em; */
/* } */
</style>
