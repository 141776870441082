import Vue from 'vue';
import VueRouter from 'vue-router';
import MainView from '../components/MainView.vue';



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainView,
  },
  {
    path: '/Bio',
    name: 'Bio',
    component: MainView, // Using MainView for the /Bio route
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    }
    return { x: 0, y: 0 };
  },
});

export default router;