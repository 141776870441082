<!--

Summary of the Code:
This Vue.js component represents a popover modal that appears when a user selects an item (such as a project or research item). It displays images, information, and links related to the selected item. The VueAgile component is used to create a carousel to display images, allowing users to navigate through the images of the selected item. There are also tags and clickable links for a demo and more information. The modal can be closed by clicking the background or the close button.

Key Points:
Popover Modal: It displays when the selected prop has data.
Image Carousel: Utilizes the VueAgile component to show images in a carousel.
Info Section: Contains the title, tags, and links for demos and more info about the selected item.
Close Functionality: The modal can be closed either by clicking the background or the close button.
Styling: Handles the modal layout, image sizes, and info presentation.

-->
<template>
  <div class="popover" v-if="selected">
    <div class="content">
      <button class="close" @click="closeWindow"><Fa fa="close" /></button>
      <VueAgile
        :navButtons="false"
        :centerMode="true"
        :slidesToShow="1"
        :infinite="false"
        class="imgs"
      >
        <img
          v-for="img in selected.images"
          :key="img.img"
          :src="img.img"
          alt=""
          class="slide"
        />
      </VueAgile>
      <div class="info">
        <h3>{{ selected.titel }}</h3>
        <div class="tags">
          <span v-for="tag in selected.tags" :key="tag">{{ tag }}</span>
        </div>
        <div class="tags links">
          <a :href="selected.demo" v-if="selected.demo.length > 1">Demo</a>
          <a :href="selected.source" target="_blank">Click For More Info</a>
        </div>
        <div class="description"></div>
      </div>
    </div>
    <div class="background" @click="closeWindow"></div>
  </div>
</template>

<script>
import Fa from './globals/Fa.vue';
import { VueAgile } from 'vue-agile';

export default {
  components: {
    Fa,
    VueAgile,
  },
  props: {
    selected: Object,
    closeWindow: Function,
  },
};
</script>

<style lang="scss" scoped>
.agile img {
  max-height: 90%;
  object-fit: contain;
}
.agile {
  button {
    background: #484848;
    border: 0;
    padding: 11px;
    margin: 0px;
    box-shadow: 0px 0px 0px 6px #1d1e21 inset;
    border-radius: 100px;
  }
}

.popover {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow: auto;
  top: 0;
  display: flex;
  .background {
    background: #00000052;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
  }

  .content {
    background: #1d1e21;
    max-width: 800px;
    margin: auto;
    width: -webkit-fill-available;
    position: relative;
    padding: 20px;
  }
  .info {
    padding: 0 1.3rem 2rem 1.3rem;
    h3 {
      grid-area: txt;
      font-weight: 500;
      font-size: 1.7rem;
      line-height: 1.4;
      margin-bottom: 0rem;
      margin-top: 0;
    }

    .tags {
      display: flex;
      gap: 0.7rem;
      font-weight: 500;
      font-size: 0.8rem;
      color: var(--txt-med);
    }
    .tags.links {
      a {
        color: white;
        text-decoration: none;
        margin-top: 0.6rem;
      }
    }
  }
  .info > span {
    display: block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-med);
  }
  .imgs {
    display: flex;
    flex-direction: column; /* Ensure images are stacked vertically */
    align-items: center; /* Center images horizontally */
    justify-content: center; /* Center images vertically */
    height: 80vh; /* Limit the container height to 80% of the viewport height */
  }
  .imgs img {
    max-height: 100%; /* Make images fill the container vertically */
    max-width: 100%; /* Ensure images don't exceed the container width */
    display: block; /* Center the image horizontally within its container */
    margin: 0 auto; /* Center the image horizontally */
  }
  .close {
    background: transparent;
    border: 0;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 100;
    background: rgb(0 0 0 / 39%);
  }
}
</style>